<template>
    <div id="not-found-404">
        <div class="container d-flex">
            <div class="cta p-5 align-self-center ">
                <h1 class="display-1">404</h1>
                <h2>{{ $t('pages.404.title') }}</h2>
                <p>{{ $t('pages.404.description') }}</p>
                <router-link class="btn btn-lg btn-primary rounded-pill" to="/">
                    {{ $t('actions.back-to-home') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Page404",
    components: {},
    beforeMount() {
        this.$gtag.event('404_NOT_FOUND', {FULL_PATH: this.$route.fullPath});
    }
}
</script>

<style lang="scss" scoped>
#not-found-404 {
    background: url('/img/background.png');
    background-size: cover;
    background-repeat: no-repeat;

    .container {
        max-width: 960px;
        margin: 0 auto;
        width: 100vw;
        height: 100vh;

        .cta {
            h1 {
                text-shadow: 0 0 5px rgba(0, 0, 0, .3);
                font-weight: bold;
                letter-spacing: -0.5vw;
                margin: 0;
                font-size: calc(1.625rem + 7.5vw);
            }
        }
    }
}
</style>